<template>
    <div style="text-align: center;">
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/01.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/02.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/03.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/04.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/05.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/06.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/07.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/08.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                @click="showInfo"
                src="/img/vipImg/09.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/10.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                @click="$router.push({name: 'doctor/agreement'});"
                src="/img/vipImg/11.gif"
        />
        <van-image
                width="100%"
                fit="contain"
                lazy-load
                src="/img/vipImg/12.jpg"
        />
        <van-image
                width="60%"
                fit="contain"
                style="bottom:20px;position: fixed;margin-left: 70px"
                lazy-load
                @click="onLoad"
                src="/img/vipImg/buy.png"
        />
    </div>
</template>

<script>
    import {Image as VanImage,Toast} from 'vant';
    import wx from 'weixin-js-sdk'
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [VanImage.name]: VanImage,
        },

        data() {
            return {};
        },
        mounted() {
            // 微信分享配置
            wx.ready(function () {
                wx.updateTimelineShareData({
                    title: '闻诚健康Vip', // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/vip', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
                wx.updateAppMessageShareData({
                    title: '闻诚健康Vip', // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/vip', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
                    desc: '', // 分享描述
                    success: function () {
                        // 设置成功
                    }
                })
            })
        },
        methods: {
            onLoad() {
                this.loadData();
            },
            showInfo(){
                Toast('正在开发中')
            },
            loadData() {
                const that=this;
                postAction('/web/api/payVip/toOrder', {
                }).then(function (response) {
                    postAction('/web/api/payVip/getRechargeInfo', {
                        "ordersn": response.result.ordersn
                    }).then(function (res) {
                        console.log(res.result);
                        wx.chooseWXPay({
                            timestamp: res.result.data.payInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            nonceStr: res.result.data.payInfo.nonceStr, // 支付签名随机串，不长于 32 位
                            package: res.result.data.payInfo.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: res.result.data.payInfo.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: res.result.data.payInfo.paySign, // 支付签名
                            success: function (res) {
                                console.log(res);
                                // 支付成功后的回调函数
                                that.$emit('ok')
                                const toast = Toast.success({
                                    duration: 0, // 持续展示 toast
                                    forbidClick: true,
                                    message: "支付成功",
                                });
                                let second = 3;
                                const timer = setInterval(() => {
                                    second--;
                                    if (second) {
                                        toast.message = "支付成功"
                                    } else {
                                        clearInterval(timer);
                                        // 手动清除 Toast
                                        Toast.clear();
                                        that.$router.push({name: 'patient/userCenter'});
                                    }
                                }, 1000);
                            }
                        });
                    })
                }).finally(() => {
                    that.loading = false;
                })


            },
            goDoctorInfo(doctorId) {
                this.$router.push({name: 'doctorInfo', query: {doctorId: doctorId}});
            }
        },
    };
</script>

<style lang="less" scoped>
    .van-image {
        display: block;
    }
</style>
