<template>
    <div style="margin-top: 200px">
        <center>
            {{title}}
        </center>
    </div>
</template>

<script>
    import Vue from 'vue';
    import {ACCESS_TOKEN} from '@/store/mutation-types'
    import {postAction} from '@/api/manage';
    import {Toast} from 'vant';

    export default {
        components: {[Toast.name]: Toast},
        data() {
            return {
                code: '',
                title: '授权成功，正在校验数据……',
                url: '/web/api/user/login'
            };
        },
        mounted() {
            this.code = this.$route.query.code;
            this.urls = decodeURI(this.$route.query.urls);
            this.toQs();
        },
        methods: {
            toQs() {
                const that = this;
              postAction(that.url, {wxCode: that.code}).then(res => {

                    if (res.result.userFlag == 0) {
                      localStorage.setItem(ACCESS_TOKEN, res.result.token)
                      // alert("set:"+ localStorage.getItem(ACCESS_TOKEN))
                        //患者
                        if (this.urls.indexOf("/doctor/") > -1) {
                            that.$router.push({name: 'patient/index'});
                        } else {
                            window.location.href = that.urls;
                        }
                    } else if (res.result.userFlag == 1) {
                        localStorage.setItem(ACCESS_TOKEN, res.result.token)
                        //医生
                        if (this.urls.indexOf("/doctor/") > -1||this.urls.indexOf("/xingqiu/") > -1) {
                            window.location.href = that.urls;
                        } else {
                            that.$router.push({name: 'doctor/userCenter'});
                        }
                    }else{
                        if (this.urls.indexOf("/doctor/") > -1) {
                            //医生
                            that.$router.push({name: 'doctor/register', query: {openId: res.result.openId,urls:that.urls}});
                        } else {
                            that.$router.push({name: 'patient/register', query: {openId: res.result.openId,urls:that.urls}});
                        }
                    }
                }).finally(() => {
                })
            }
        }
    };
</script>

<style lang="less">
</style>
