<template>
    <div style="margin-top: 200px">
        <center>
            {{title}}
        </center>
    </div>
</template>

<script>
    import {postAction} from '@/api/manage';
    import {Toast} from 'vant';

    export default {
        components: {[Toast.name]: Toast},
        data() {
            return {
                code: '',
                title: '系统正在授权中……',
                url: '/web/api/user/getLoginUrl'
            };
        },
        mounted() {
            this.urls = this.$route.query.urls;
            this.toQs();
        },
        methods: {
            toQs() {
                postAction(this.url, {urls: this.urls}).then(res => {
                    window.location.href=res.result.data;
                }).finally(() => {
                })
            }
        }
    };
</script>

<style lang="less">
</style>
