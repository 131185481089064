<template>
    <div style="margin-top: 200px">
        <center>
            {{title}}
        </center>
    </div>
</template>

<script>
    import {postAction} from '@/api/manage';
    import {Toast} from 'vant';

    export default {
        components: {[Toast.name]: Toast},
        data() {
            return {
                code: '',
                title: '系统正在授权中……',
                url: '/web/api/auth/bgOrderCs'
            };
        },
        mounted() {
            this.code = this.$route.query.code;
            this.toQs();
        },
        methods: {
            toQs() {
                postAction(this.url, {code: this.code}).then(res => {
                    this.title = res.content;
                    Toast.success(res.content);
                }).finally(() => {
                })
            }
        }
    };
</script>

<style lang="less">
</style>
